import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import HomeScreen from "../pages/home/index";

const RootNavigator: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        {/* <Route path="/ideas" element={<IdeasScreen />} /> */}
      </Routes>
    </Router>
  );
};

export default RootNavigator;
