import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const HomeScreen: React.FC = () => {
  return (
    <section className="d-flex justify-content-between align-items-center pt-5 pb-4 bold flex-column vh-100">
      <div>
        <Link className="navbar-brand  bg-white" to="/">
          <img src="/uncoverthefuture.svg" width="55" alt="uncoverthefuture" />
        </Link>
      </div>
      <div className={styles.headerText}>
        <img src="/uncoverthefuture.svg" width="55" alt="uncoverthefuture" />
        <h1 className="font-weight-bold">ncover the future</h1>
      </div>
      <div>
        <span>© 2024 uncoverthefuture. All Rights Reserved.</span>
      </div>
    </section>
  );
};

export default HomeScreen;
