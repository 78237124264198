import React from "react";
import "../app.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RootNavigator from "../navigations/routes";

const App: React.FC = () => {
   return (
      <main>
         <RootNavigator/>
      </main>
   );
};

export default App;
